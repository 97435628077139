import {
  Box,
  VStack,
  Text
} from '@chakra-ui/react';
import { PcDisplayBlock } from '@/components';
import { Image } from '@/components/Image';
import { LinkButton } from '@/components/Link/LinkButton/LinkButton';
import { HeadingSubText } from '@/components/Text/HeadingSubText/HeadingSubText';
import { getPageInfo } from '@/config/pages';
import { SecondViewContents } from '@/features/brand/types/brand';

export type BrandSecondViewProps = {
  secondViewContents: SecondViewContents;
}

export const BrandSecondView = ({
  secondViewContents: {
    brandLogoImage,
    brandName,
    subHeading,
    subDescription,
    secondViewImage,
    secondViewImageAlt,
    buttonColor
  }
}: BrandSecondViewProps) => {
  return (

    <Box
      alignItems={'center'}
      display={'flex'}
      justifyContent={'space-evenly'}
      flexDirection={{ base: 'column', md: 'row' }}
      mx={{ lg: '68px' }}
    >
      <Box
        maxW={{ lg: '432px' }}
        pr={6}
        pl={2}
      >
        <VStack
          pl={{ base: 6, lg: 0 }}
          pr={{ base: 6, lg: 0 }}
          alignItems={'left'}
        >
          <PcDisplayBlock
            maxW={'400px'}
          >
            <Box
              position={'relative'}
              height={'100px'}
              my={3}
              alignItems={'left'}
              as={'h2'}
            >
              <Image
                src={brandLogoImage}
                alt={brandName}
                fill
                style={{ objectFit: 'contain' }}
              />
            </Box>
          </PcDisplayBlock>
          <Box>
            <Text
              textStyle={'h3'}
              letterSpacing={'2px'}
              fontWeight={'bold'}
              whiteSpace={'pre-line'}
              textAlign={'justify'}
            >
              {subHeading}
            </Text>
            <HeadingSubText
              textAlign={'justify'}
              pt={3}
            >
              {subDescription}
            </HeadingSubText>
          </Box>
          {brandName !== 'Re.Ra.Ku PRO' &&
            <PcDisplayBlock
              textAlign={'left'}
            >
              <LinkButton
                w={{ base: '80%', lg: '60%' }}
                bg={'white'}
                color={buttonColor}
                size={'lg'}
                text={'はじめての方へ'}
                linkUrl={getPageInfo('guide', {})}
              />
            </PcDisplayBlock>
          }
        </VStack>
      </Box>
      <PcDisplayBlock>
        <Box>
          <Image
            src={secondViewImage}
            alt={secondViewImageAlt}
            width={640}
            height={360}
          />
        </Box>
      </PcDisplayBlock>
    </Box>
  );
};
