import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  HStack,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { ReturnPageInfo } from '@/config/pages';

interface LinkButtonProps extends BoxProps {
  text: string;
  size?: string;
  linkUrl: ReturnPageInfo;
  color?: string;
}

export const LinkButton = ({
  text,
  linkUrl,
  color = 'brand.500',
  ...props
}: LinkButtonProps) => {
  return (
    <Box
      {...props}
    >
      <Link
        href={linkUrl.href}
      >
        <Box
          borderColor={color}
          border={'1px solid'}
          bg={'white'}
          color={color}
          cursor={'pointer'}
          borderRadius={'106px'}
          _hover={{
            bg: color,
            color: 'white'
          }}
        >
          <HStack
            justifyContent={'center'}
            padding={'12px'}
          >
            <Text
              textStyle={'h5'}
              fontWeight={'bold'}
              pl={2}
              letterSpacing={'1px'}
            >
              {text}
            </Text>
            <Box
              ml={'auto'}
            >
              <ArrowForwardIcon/>
            </Box>
          </HStack>
        </Box>
      </Link>
    </Box>
  );
};
