import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useMemo } from 'react';
import { BiMap } from 'react-icons/bi';
import { LabelButton } from '@/components/Button/LabelButton/LabelButton';
import { getPageInfo } from '@/config/pages';
import { useGetLocation } from '@/features/shops/components/hooks/useGetLocation';
import { SearchButtonByLocation } from '@/features/shops/components/ui/ShopSearch/ShopSearchAreaTop/SearchButtonByLocation';

export type ShopSearchFixedFooterProps = {};

export const ShopSearchFixedFooter = ({}: ShopSearchFixedFooterProps) => {
  const getLocation = useGetLocation();
  const { getLocationOnlyShopSearchHandler, isCurrentLocationLoading } = getLocation;

  const handleSearchShopLocationOnly = useMemo(() => {
   return getLocationOnlyShopSearchHandler();
  },[getLocationOnlyShopSearchHandler]);

  return (
    <Box
      py={4}
      bg={'rgba(255,255,255,0.4)'}
      css={{
        backdropFilter: 'blur(15px)',
      }}
      textAlign={'center'}
      boxShadow={'0px -2px 16px rgb(0 0 0 / 14%)'}
    >
      <Flex
        justifyContent={'center'}
      >
        <LabelButton
          bg={'brand.500'}
          color={'white'}
          borderColor={'brand.500'}
          width={'45%'}
          height={'40px'}
          borderRadius={'50px'}
          px={6}
        >
          <Link
            href={getPageInfo('studio', {}).href}
          >
            <Text
              fontWeight={'bold'}
              textStyle={'h4'}
              pt={'1px'}
            >
              店舗を探す
            </Text>
          </Link>
        </LabelButton>
        <Box
          width={'50%'}
        >
          <SearchButtonByLocation
            icon={<BiMap/>}
            margin={{ base: 0 }}
            getLocation={handleSearchShopLocationOnly}
            isLocationLoading={isCurrentLocationLoading}
          />
        </Box>
      </Flex>
    </Box>
  );
};
