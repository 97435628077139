import {
  Box
} from '@chakra-ui/react';
import { Image } from '@/components/Image';
import { TopViewContents } from '@/features/brand/types/brand';

export type PcBrandTopViewProps = {
  topViewContents: TopViewContents
}

export const PcBrandTopView = ({
  topViewContents: {
    pcHeadingImageUrl,
    headingAlt,
    pcTopImageUrl,
    topImageAlt
  }
}: PcBrandTopViewProps) => {
  return (
    <>
      <Box
        zIndex={3}
        width={'80%'}
        ml={'100px'}
        mt={20}
        textAlign={'left'}
        as={'h1'}
      >
        <Image
          src={pcHeadingImageUrl}
          alt={headingAlt}
          height={120}
          width={350}
          loading={'lazy'}
        />
      </Box>
      <Box
        ml={'-400px'}
      >
        <Image
          src={pcTopImageUrl}
          alt={topImageAlt}
          height={1125}
          width={2000}
        />
      </Box>
    </>
  );
};
