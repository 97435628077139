import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { BrandSecondView } from '../BrandSecondView/BrandSecondView';
import { PcBrandCards } from '../Pc/PcBrandCards';
import { PcBrandTopView } from '../Pc/PcBrandTopView';
import { SpBrandTopView } from '../Sp/SPBrandTopView';
import { SpBrandSwiper } from '../Sp/SpBrandSwiper';
import {
  ContentWrapper,
  PcDisplayBlock,
  PcSideSearchLink,
  SpDisplayBlock
} from '@/components';
import { Image } from '@/components/Image';
import { LinkButton } from '@/components/Link/LinkButton/LinkButton';
import { getPageInfo } from '@/config/pages';
import {
  BrandCard,
  HeadingImage,
  QuestionAndAnswer,
  SecondViewContents,
  ThemeColors,
  TopViewContents
} from '@/features/brand/types/brand';
import { FaqArea } from '@/features/faq/components/ui/FaqArea/FaqArea';
import { ShopSearchFixedFooter } from '@/features/top/components/ui/ShopSearchFixedFooter/ShopSearchFixedFooter';

export type BrandPageLayoutProps = {
  topViewContents: TopViewContents;
  secondViewContents: SecondViewContents;
  brandCards: BrandCard[];
  themeColors: ThemeColors;
  headingImage: HeadingImage;
  questionAndAnswers: QuestionAndAnswer[];
  children: ReactNode;
  sliderHeight: string;
};

export const BrandPageLayout = ({
  topViewContents,
  secondViewContents,
  brandCards,
  headingImage: {
    headingAboutImage,
    headingFaqImage
  },
  themeColors,
  questionAndAnswers,
  children,
  sliderHeight,
}: BrandPageLayoutProps) => {
  return (
    <>
      <ContentWrapper>
        <PcDisplayBlock
          display={{ base: 'none', lg: 'flex' }}
          justifyContent={'center'}
          alignItems={'flex-start'}
        >
          <PcSideSearchLink/>
          <PcBrandTopView
            topViewContents={topViewContents}
          />
        </PcDisplayBlock>
        <SpDisplayBlock>
          <SpBrandTopView
            topViewContents={topViewContents}
          />
        </SpDisplayBlock>
      </ContentWrapper>

      <ContentWrapper
        py={{ base: 6, lg: 10 }}
      >
        <BrandSecondView
          secondViewContents={secondViewContents}
        />
      </ContentWrapper>
      <ContentWrapper
        py={{ base: 10, lg: 10 }}
        bg={{ base: themeColors.brandBgColor, lg: 'white' }}
      >
        <Box
          position={'relative'}
          height={'60px'}
          mt={4}
          mb={10}
          as={'h2'}
        >
          <Image
            src={headingAboutImage}
            alt={'こだわり'}
            fill
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <PcDisplayBlock
          display={{ base: 'none', lg: 'flex' }}
          margin={'0 auto'}
          justifyContent={'center'}
        >
          <PcBrandCards
            brandCards={brandCards}
            colorScheme={themeColors}
          />
        </PcDisplayBlock>

        <SpDisplayBlock
          justifyContent={'center'}
          mx={'30px'}
        >
          <SpBrandSwiper
            brandSwiper={brandCards}
            swiperColor={themeColors.brandColor}
            sliderHeight={sliderHeight}
            position={'relative'}
            textAlign={'center'}
          />
        </SpDisplayBlock>
      </ContentWrapper>

      {children}

      <ContentWrapper
        m={'auto'}
      >
        <Box
          textAlign={'center'}
          pb={{ base: 4, lg: 0 }}
        >
          <Box
            as={'h2'}
            position={'relative'}
            height={'65px'}
            my={{ base: 0, lg: 10 }}
          >
            <Image
              src={headingFaqImage}
              alt={'よくある質問'}
              fill
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </Box>
        <PcDisplayBlock
          px={'150px'}
          mt={30}
          pb={20}
          bg={'white'}
        >
          <FaqArea
            faqItems={questionAndAnswers}
            accordionPanelColor={'white'}
            accordionFontColor={themeColors.brandColor}
            activeAccordionFontColor={themeColors.brandColor}
            activeAccordionColor={themeColors.brandBgColor}
          />
        </PcDisplayBlock>
        <SpDisplayBlock>
          <Box
            px={'4px'}
          >
            <FaqArea
              faqItems={questionAndAnswers}
              accordionPanelColor={'white'}
              accordionFontColor={themeColors.brandColor}
              activeAccordionFontColor={themeColors.brandColor}
              activeAccordionColor={themeColors.brandBgColor}
            />
          </Box>
        </SpDisplayBlock>
      </ContentWrapper>
      <Box
        pt={{ base: 8, lg: 0 }}
        pb={{ base: 8, lg: 8 }}
        bg={'white'}
      >
        <LinkButton
          m={'0 auto'}
          w={{ base: '80%', lg: '30%' }}
          bg={'white'}
          color={themeColors.brandColor}
          size={'lg'}
          text={'MORE'}
          linkUrl={getPageInfo('faq', {})}
        />
      </Box>
      <SpDisplayBlock
        zIndex={'sticky'}
        position={'sticky'}
        bottom={0}
      >
        <ShopSearchFixedFooter/>
      </SpDisplayBlock>
    </>
  );
};
